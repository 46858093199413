<template>
  <div>
    <svg
      class="shape"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill-opacity="1"
        d="M0,192L60,192C120,192,240,192,360,181.3C480,171,600,149,720,160C840,171,960,213,1080,213.3C1200,213,1320,171,1380,149.3L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
      ></path>
    </svg>
    <v-footer
      color="secondary"
      padless
      flat
    >
      <v-row
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <div>
            <Logo></Logo>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="center"
        >
          <div class="spacer-s">
            <a
              class="link mr-5"
              @click="$router.push('/')"
            >
              <p class="font-weight-regular mb-1 mt-1">HOME</p>
            </a>
            <a
              class="link ml-5"
              @click="$router.push('/Gallery')"
            >
              <p class="font-weight-regular mb-1 mt-1">GALLERY</p>
            </a>
          </div>
        </v-col>

        <v-col cols="12">
          <p class="pt-4 text-center copyright">Copyright {{ new Date().getFullYear() }} | Mari Brønseth | All rights reserved</p>
        </v-col>
        <v-col cols="12">
          <p class="pb-4 text-center">
            Developed by
            <a
              class="link"
              rel="noopener"
              target="_blank"
              href="https://erlendweb.no"
            >Erlend Brønseth</a>
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import Logo from "../assets/Logo.vue";

export default {
  components: {
    Logo,
  },
}
</script>

<style scoped>
.v-footer {
  background-color: var(--v-secondary-base);
}

.shape {
  margin-bottom: -20px;
  background-color: var(--v-primary-base);
}

.shape path {
  fill: var(--v-secondary-base);
}

@media only screen and (max-width: 575px) {
  .copyright {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1200px) {
  .shape {
    margin-top: -150px;
  }
}
</style>
